import {on} from 'delegated-events';

on('click', '#cookienotice .accept, #cookienotice .ok', (e) => {
  const cookiebox = document.querySelector('#cookienotice');
  cookiebox.classList.add("closed");
  let date = new Date(Date.now() + 86400e3*365);
  let expdat = date.toUTCString();
  document.cookie="ttzacceptcookie=true; expires="+ expdat +"; path=/";
});



on('click', '#news_flash .hide_button', function (e) {
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const newsbox = document.querySelector('#news_flash');
  newsbox.classList.add("closed");
  let seen_news_flashes = getCookie('seen_news_flashes');
  let newid = this.getAttribute('data-news-flash-id');
  seen_news_flashes = seen_news_flashes + ',' + newid;
  let date = new Date(Date.now() + 86400e3*7);
  let expdat = date.toUTCString();
  document.cookie= `seen_news_flashes=${seen_news_flashes}; expires=${expdat}; path=/`;
});

on('click', '#primary_alert .hide', (e) => {
  const alertbox = document.querySelector('#primary_alert');
  alertbox.classList.add("closed");
  let date = new Date(Date.now() + 86400e3);
  let expdat = date.toUTCString();
  document.cookie= `seen_alert=true; expires=${expdat}; path=/`;
});