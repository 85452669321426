// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

require("application/show_on_click.js")
require("application/prevent_default_hash.js")
require("application/cookie.js")
require("application/show_faq_on_click.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



//  put here rather than putting in a seperate public.js filenlike it is in lessonlabs


window.$closest = function(el, sel) {
  if (el) {
    return el.matches(sel)
           ? el
           : $closest(el.parentNode, sel);
  }
}
